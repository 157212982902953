@import "lib";
body {
 background:url(../images/bg.jpg);
 background-repeat: no-repeat;
 font-family: 'Open Sans', sans-serif;
 font-size:13px;
 color:#4d4d4d;
}  
a:focus, a:hover {
    color: #23527c;
    text-decoration: none !important;
}

body.app {
 background:url(../images/bg.jpg);
 background-repeat: no-repeat;

}    


img {
  max-width: 100%;
  height: auto;
}

.btn-default {
    color: #fff;
    background-color: #49366f;
    border-color: #49366f;
    border:none;
}

.btn-default.notnow {
   margin-top:10px;   
}    

.btn {
  border-radius:0;   
} 

input[type="submit"].btn-default,
input[type="button"].btn-default,
button.btn-default {
  width:140px; 
  text-transform: uppercase;
  font-size:16px;
}    

p.button {
        clear:both; 
        padding-top:20px; 
        margin-top:20px;
        text-align: center;
         margin-bottom:30px;
        a.btn {
         display: inline-block;    
         width:150px;  
         text-transform: uppercase;
         padding:9px 12px;
        }    
     }     

p.nobottommargin {
   margin-bottom: 0; 
}  

.block {
  display:block;   
}    

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background:rgba(95,69,149,.95) !important;   
  width:5px !important;
}    

.feedbar .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.notification .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background:rgba(40,40,40,.95) !important;   
  width:5px !important;
}    

#mCSB_1_scrollbar_vertical .mCSB_dragger,
#mCSB_3_scrollbar_vertical .mCSB_dragger,
.mCustomScrollBox .mCSB_dragger 
 { height: 50px !important;  }

.mCSB_inside>.mCSB_container {
    margin-right: 15px !important;
}


/*progress bar*/

*,
*:before,
*:after {
  box-sizing: border-box;
}

.set-size {
  font-size: 10em;
}
.charts-container:after {
  clear: both;
  content: "";
  display: table;
}
.pie-wrapper {
  height: 1em;
  width: 1em;
  float: left;
  margin: 15px;
  position: relative;
}
.pie-wrapper:nth-child(3n+1) {
  clear: both;
}
.pie-wrapper .pie {
  height: 100%;
  width: 100%;
  clip: rect(0, 1em, 1em, 0.5em);
  left: 0;
  position: absolute;
  top: 0;
}
.pie-wrapper .pie .half-circle {
  height: 100%;
  width: 100%;
  border: 0.08em solid #3498db;
  border-radius: 50%;
  clip: rect(0, 0.5em, 1em, 0);
  left: 0;
  position: absolute;
  top: 0;
}
.pie-wrapper .label {
  background: #34495e;
  border-radius: 50%;
  bottom: 0.4em;
  color: #ecf0f1;
  cursor: default;
  display: block;
  font-size: 0.25em;
  left: 0.4em;
  line-height: 2.6em;
  position: absolute;
  right: 0.4em;
  text-align: center;
  top: 0.4em;
}
.pie-wrapper .label .smaller {
  color: #bdc3c7;
  font-size: .45em;
  padding-bottom: 20px;
  vertical-align: super;
}
.pie-wrapper .shadow {
  height: 100%;
  width: 100%;
  border: 0.1em solid #bdc3c7;
  border-radius: 50%;
}
.pie-wrapper.style-2 .label {
  background: none;
  color: #7f8c8d;
}
.pie-wrapper.style-2 .label .smaller {
  color: #bdc3c7;
}
.pie-wrapper.progress-30 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-30 .pie .half-circle {
  border-color: #3498db;
}
.pie-wrapper.progress-30 .pie .left-side {
  -webkit-transform: rotate(108deg);
          transform: rotate(108deg);
}
.pie-wrapper.progress-60 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-60 .pie .right-side {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.pie-wrapper.progress-60 .pie .half-circle {
  border-color: #9b59b6;
}
.pie-wrapper.progress-60 .pie .left-side {
  -webkit-transform: rotate(216deg);
          transform: rotate(216deg);
}
.pie-wrapper.progress-90 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-90 .pie .right-side {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.pie-wrapper.progress-90 .pie .half-circle {
  border-color: #e67e22;
}
.pie-wrapper.progress-90 .pie .left-side {
  -webkit-transform: rotate(324deg);
          transform: rotate(324deg);
}
.pie-wrapper.progress-45 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-45 .pie .half-circle {
  border-color: #1abc9c;
}
.pie-wrapper.progress-45 .pie .left-side {
  -webkit-transform: rotate(162deg);
          transform: rotate(162deg);
}
.pie-wrapper.progress-75 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-75 .pie .right-side {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.pie-wrapper.progress-75 .pie .half-circle {
  border-color: #7cc576;
}
.pie-wrapper.progress-75 .pie .left-side {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.pie-wrapper.progress-95 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-95 .pie .right-side {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.pie-wrapper.progress-95 .pie .half-circle {
  border-color: #e74c3c;
}
.pie-wrapper.progress-95 .pie .left-side {
  -webkit-transform: rotate(342deg);
          transform: rotate(342deg);
}
.pie-wrapper--solid {
  border-radius: 50%;
  overflow: hidden;
}
.pie-wrapper--solid:before {
  border-radius: 0 100% 100% 0%;
  content: '';
  display: block;
  height: 100%;
  margin-left: 50%;
  -webkit-transform-origin: left;
          transform-origin: left;
}
.pie-wrapper--solid .label {
  background: transparent;
}
.pie-wrapper--solid.progress-65 {
  background: -webkit-linear-gradient(left, #e67e22 50%, #34495e 50%);
  background: linear-gradient(to right, #e67e22 50%, #34495e 50%);
}
.pie-wrapper--solid.progress-65:before {
  background: #e67e22;
  -webkit-transform: rotate(126deg);
          transform: rotate(126deg);
}
.pie-wrapper--solid.progress-25 {
  background: -webkit-linear-gradient(left, #9b59b6 50%, #34495e 50%);
  background: linear-gradient(to right, #9b59b6 50%, #34495e 50%);
}
.pie-wrapper--solid.progress-25:before {
  background: #34495e;
  -webkit-transform: rotate(-270deg);
          transform: rotate(-270deg);
}
.pie-wrapper--solid.progress-88 {
  background: -webkit-linear-gradient(left, #3498db 50%, #34495e 50%);
  background: linear-gradient(to right, #3498db 50%, #34495e 50%);
}
.pie-wrapper--solid.progress-88:before {
  background: #3498db;
  -webkit-transform: rotate(43.2deg);
          transform: rotate(43.2deg);
}


.bx-wrapper {
  background:transparent;   
  border:0 !important;
  box-shadow: none !important;
  padding-top:20px;
  margin-bottom: 15px !important;
}

.weeks .bx-wrapper {
   padding-top:0px !important;  
   margin-top:10px;
       .bx-prev {
        left: 5px;  
        background:url(../images/button-previous.png);
        background-repeat: no-repeat;
        
    }
    
    .bx-next {
        right: -42px;  
        background:url(../images/button-next.png);
        background-repeat: no-repeat;
    }
}    


.fiterchoicewrap .bx-wrapper {
   padding-top:0px !important;  
   margin-top:10px;
       .bx-prev {
        left: 115px;  
        top:95%;
        background:url(../images/but-next-fitterchoice.png);
        background-repeat: no-repeat;
        
    }
    
    .bx-next {
        right: 335px;  
        top:95%;
        background:url(../images/but-prev-fitterchoice.png);
        background-repeat: no-repeat;
    }
}    


.popcontent .bx-wrapper {
   padding-top:0px !important;  
   margin-top:10px;
       .bx-prev {
        left: -15px;  
        top:55%;
        background:url(../images/popup-previous.png);
        background-repeat: no-repeat;
        height:80px;
        
    }
    
    .bx-next {
        right: -22px;  
        top:55%;
        background:url(../images/popup-next.png);
        background-repeat: no-repeat;
        height:80px;
    }
}    

.bxslider li {
  border-right:1px solid #f7f7f7; 
}  

  .bx-wrapper .bx-controls-direction a {
    top:60%;   
  }  
  
  .bx-wrapper .bx-next:hover, .bx-wrapper .bx-next:focus {
     background-position: 0 0; 
}

.bx-wrapper .bx-prev {
    left: -52px;  
    background:url(../images/slider-arrow-left.png);
    background-repeat: no-repeat;
    
}

.bx-wrapper .bx-next {
    right: -72px;  
    background:url(../images/slider-arrow-right.png);
    background-repeat: no-repeat;
}

    
//tooltip styles

.customtooltip {
    display:none;    
    font-size:12px;   
    padding:2px;   
    z-index: 99;   
/* for IE */
  filter:alpha(opacity=80);
  /* CSS3 standard */
  opacity:0.8;
}

//remove download button for chrome
video::-internal-media-controls-download-button {
    display:none;
}

video::-webkit-media-controls-enclosure {
    overflow:hidden;
}

video::-webkit-media-controls-panel {
    width: calc(100% + 30px); /* Adjust as needed */
}

.video-js .vjs-big-play-button {
    left: 40% !important;
    top: 40% !important;
    width: 20%;
    height: 20%;
}

.video-js .vjs-play-control:before {
    top:20% !important;
    content: '\f101';
    font-size: 48px;
}

.video-js .vjs-play-control {
  top:-5px !important;   
}    

.input-hidden {
  position: absolute;
  left: -9999px;
}

input[type=radio]:checked + label.male {
  background: url(../images/icon-male-active.jpg) no-repeat;
  width:33px;
  height:27px;  
  text-indent:-999999px;
}

input[type=radio]:checked + label.female {
  background: url(../images/icon-female-active.jpg) no-repeat;
  width:33px;
  height:27px;  
  text-indent:-999999px;
}
/* remove gutter spacing outside */

label.male {
  width:33px;
  height:27px;  
   background: url(../images/icon-male.png) no-repeat;
   display: inline-block;
   text-indent:-999999px;
   cursor: pointer;
}

label.male:hover {
    background: url(../images/icon-male-active.jpg) no-repeat; 
    width:33px;
    height:27px;  
}

label.female {
  width:33px;
  height:27px;  
   background: url(../images/icon-female.png) no-repeat;
   display: inline-block;
   text-indent:-999999px;
   cursor: pointer;
}

label.female:hover {
    background: url(../images/icon-female-active.jpg) no-repeat; 
    width:33px;
    height:27px;  
}

/* bootstrap style is missing as the class name is different*/
.control  {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}